import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { HeaderLinkSubGroupResult } from '../data/content/HeaderLinkSubGroupFragment'
import { Icon } from './Icon'
import { ImageSizeByArea } from './ImageSizeByArea'
import { Link } from './Link'
import styles from './SubNavigationLink.module.sass'

export type SubNavigationLinkProps = NonNullable<HeaderLinkSubGroupResult['links']>['items'][number]

type AditionalProps = {
	type?: 'login' | 'default'
}

export const SubNavigationLink: FunctionComponent<SubNavigationLinkProps & AditionalProps> = ({
	type,
	icon,
	link,
	description,
}) => {
	if (type === 'login') {
		return (
			<div className={styles.wrapper}>
				<Link link={link} className={clsx(styles.link, styles.is_login)}>
					<div className={clsx(icon?.icon && styles.view_logoLogin)}>
						{icon?.icon && (
							<div className={styles.icon}>
								<ImageSizeByArea image={icon.icon} imageMaxArea={24} />
							</div>
						)}
						<div className={styles.icon}>{link?.title}</div>
					</div>
				</Link>
			</div>
		)
	}

	return (
		<div className={styles.wrapper}>
			<Link link={link} className={styles.link}>
				<div className={clsx(styles.main, icon?.icon && styles.view_logo)}>
					<div className={clsx(styles.titleIcon, description && styles.stylesWithDescription)}>
						{icon?.icon && (
							<div className={styles.icon}>
								<ImageSizeByArea image={icon.icon} imageMaxArea={24} />
							</div>
						)}
						<div className={styles.content}>
							<div className={styles.contentLabel}>
								<span className={styles.title}>{link?.title}</span>
								<span className={styles.arrow}>
									<Icon name="arrow" />
								</span>
							</div>
						</div>
					</div>
					<div className={styles.description}>{description}</div>
				</div>
			</Link>
		</div>
	)
}
