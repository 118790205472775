import clsx from 'clsx'
import { createContext, FunctionComponent, ReactNode, useContext } from 'react'
import styles from './ThemeProvider.module.sass'

// Dark theme is currently not used anywhere and so removing it from codebase is suggested. But there is a chance that it will be used in the future once again.

export type Theme = {
	site: 'ceros' | 'gemma'
	primary: 'light' | 'dark'
	modifier: 'none' | 'gemmaLanding' | 'gemmaGeneric'
}

const context = createContext<Theme>({ primary: 'light', modifier: 'none', site: 'ceros' })

export const ThemeProvider: FunctionComponent<{
	children: ReactNode
	theme: Theme
}> = ({ children, theme }) => {
	return (
		<context.Provider value={theme}>
			<div
				className={clsx(
					styles.wrapper,
					styles[`is_theme_${theme.primary}`],
					styles[`is_modifier_${theme.modifier}`]
				)}>
				{children}
			</div>
		</context.Provider>
	)
}

export const useTheme = (): Theme => useContext(context)
