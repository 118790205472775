import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent, ReactNode } from 'react'
import type { HeaderGemmaResult } from '../data/content/HeaderGemmaFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './GemmaHeader.module.sass'

type GemmaHeaderProps = HeaderGemmaResult

export const GemmaHeader: FunctionComponent<GemmaHeaderProps> = ({
	gemmaLogo,
	gemmaLogoLink,
	logoText,
	logo,
	logoLink,
}) => {
	return (
		<header className={styles.wrapper}>
			<div className={styles.main}>
				{gemmaLogo && (
					<div className={styles.column}>
						<GemmaLink link={gemmaLogoLink}>
							<Image
								src={gemmaLogo.url}
								width={gemmaLogo.width}
								height={gemmaLogo.height}
								alt={gemmaLogo.alt ?? gemmaLogo.fileName ?? ''}
							/>
						</GemmaLink>
					</div>
				)}
				<div className={clsx(styles.column, styles.is_cerosLogo)}>
					<span className={styles.text}>{logoText}</span>
					{logo && (
						<GemmaLink link={logoLink}>
							<Image
								src={logo.url}
								width={logo.width}
								height={logo.height}
								alt={logo.alt ?? logo.fileName ?? ''}
							/>
						</GemmaLink>
					)}
				</div>
			</div>
		</header>
	)
}

type GemmaLinkProps = {
	link?: LinkResult
	children: ReactNode
}

const GemmaLink: FunctionComponent<GemmaLinkProps> = ({ link, children }) => {
	if (link) {
		return (
			<Link href={contemberLinkToHref(link)} className={styles.link}>
				{children}
			</Link>
		)
	}

	return <>{children}</>
}
