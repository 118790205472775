import clsx from 'clsx'
import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { useIsInView } from '../utilities/useIsInView'
import styles from './ChangeTextToShape.module.sass'
import { Icon, iconNames } from './Icon'

const defaultShapes = { splashStar: 'splashStar' } as const
type DefaultShapes = (typeof defaultShapes)[keyof typeof defaultShapes]

export type ChangeTextToShapeProps = {
	shape: DefaultShapes
	shapeColor: string
	children: React.ReactNode
}

export type ChangeTextToShapeElement = {
	type: 'changeTextToShape'
	suchThat: { shape: DefaultShapes; shapeColor: string | null }
}

const noAnimation: Record<string, boolean> = {
	arch: true,
	rainbow: true,
	gummyBear: true,
	triangle: true,
	sixtuplet: true,
}

const onViewAnimate: Record<string, boolean> = {
	cikica: true,
}

export const ChangeTextToShape: FunctionComponent<ChangeTextToShapeProps> = ({
	shape,
	shapeColor = '#d3d333',
	children,
}) => {
	const [rotationDegrees, setRotationDegrees] = useState<number>(0)
	const { y: scrollY } = useWindowScroll()

	const wrapperRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(wrapperRef, {
		threshold: 0.6,
	})

	useLayoutEffect(() => {
		setRotationDegrees(scrollY)
	}, [scrollY])

	return (
		<span
			style={{
				color: shapeColor,
				transition: 'transform 0.1s linear',
				transform:
					noAnimation[shape] || onViewAnimate[shape] ? 'none' : `rotate(${rotationDegrees}deg)`,
			}}
			className={clsx(
				styles.wrapper,
				isInView && onViewAnimate[shape] && styles.is_inView,
				noAnimation[shape] && styles.no_animation,
				onViewAnimate[shape] && styles.bigger
			)}
			ref={wrapperRef}>
			<span className={styles.is_hidden}>{children}</span>

			{iconNames.includes(shape) && <Icon name={shape} />}
		</span>
	)
}
