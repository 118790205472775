import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { lazyBoundary } from '../images/configuration'
import styles from './ImageSizeByArea.module.sass'

export type ImageSizeByAreaProps = {
	baseSize?: number
	imageMaxArea?: number
	image: ImageResult
}

export const ImageSizeByArea: FunctionComponent<ImageSizeByAreaProps> = ({
	baseSize = 120,
	imageMaxArea = 60,
	image,
}) => {
	const imageSize = (image: ImageResult): { width: number; height: number } => {
		if (!(image.width && image.height)) {
			return { width: 0, height: 0 }
		}

		const baseAspectRatio = image.width / image.height
		let baseWidth = baseSize
		let baseHeight = baseWidth / baseAspectRatio

		if (baseAspectRatio < 1) {
			baseHeight = baseSize
			baseWidth = baseHeight * baseAspectRatio
		}

		const baseArea = baseWidth * baseHeight
		const maxArea = imageMaxArea * imageMaxArea
		const areaRatio = Math.min(Math.sqrt(maxArea) / Math.sqrt(baseArea), 1)

		return { width: baseWidth * areaRatio, height: baseHeight * areaRatio }
	}

	const { width, height } = imageSize(image)

	return (
		<div className={styles.wrapper}>
			<div
				className={styles.image}
				style={{
					'--ImageSizeByArea-logo-width': `${width}px`,
					'--ImageSizeByArea-logo-height': `${height}px`,
				}}>
				<Image
					lazyBoundary={lazyBoundary}
					src={image.url}
					layout="fill"
					objectFit="cover"
					alt={image.alt ?? ''}
					sizes={`${width}px`}
				/>
			</div>
		</div>
	)
}
