import clsx from 'clsx'
import { HidingHeader } from 'hiding-header-react'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo, useState } from 'react'
import { SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import type { FooterResult } from '../data/content/FooterFragment'
import type { HeaderResult } from '../data/content/HeaderFragment'
import type { HeaderGemmaResult } from '../data/content/HeaderGemmaFragment'
import type { SelfPromotionResult } from '../data/content/SelfPromotionFragment'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './LayoutGeneric.module.sass'
import { SelfPromotion } from './SelfPromotion'
import { useTheme } from './ThemeProvider'

export type LayoutGenericProps = {
	gemmaHeader?: HeaderGemmaResult | null
	header?: HeaderResult
	children: React.ReactNode
	footer?: FooterResult
	selfPromotion?: SelfPromotionResult | null
}

export const LayoutGeneric: FunctionComponent<LayoutGenericProps> = ({
	selfPromotion,
	children,
	gemmaHeader,
	header,
	footer,
}) => {
	const { asPath } = useRouter()

	const [isHeaderHome, setIsHeaderHome] = useState(true)

	const headerContent = useMemo(
		() => header && <Header headerGemma={gemmaHeader} header={header} isHome={isHeaderHome} />,
		[gemmaHeader, header, isHeaderHome]
	)

	const theme = useTheme()
	const isGemmaLanding = theme.modifier === 'gemmaLanding'
	const isSticky = !isGemmaLanding

	const isLoginPage: boolean = asPath === '/login/' ? true : false

	if (isLoginPage) {
		return <div className={clsx(styles.main, isLoginPage && styles.isLoginPage)}>{children}</div>
	}

	return (
		<div className={clsx(styles.wrapper, isGemmaLanding && styles.is_gemma)}>
			{selfPromotion?.content && !isGemmaLanding && (
				<SelfPromotion content={selfPromotion.content} />
			)}
			{isSticky ? (
				<HidingHeader
					onHomeChange={(isHome) => {
						setIsHeaderHome(isHome)
					}}>
					{headerContent}
				</HidingHeader>
			) : (
				headerContent
			)}
			<SharedProgressLoadingIndicator />

			<div className={styles.main}>{children}</div>
			{footer && <Footer footer={footer} />}
		</div>
	)
}
