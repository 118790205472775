import clsx from 'clsx'
import React, { FunctionComponent, useRef } from 'react'
import { usePlatform } from 'react-use-platform'
import { useIsInView } from '../utilities/useIsInView'
import style from './BackgroundColorText.module.sass'

export type BackgroundColorTextProps = {
	backgroundColor?: string
	isOutline?: boolean
	isOpenCircle?: boolean
	isClosedCircleDown?: boolean
	isOverlappedCircleUp?: boolean
	borderColor?: string
	children: React.ReactNode
}

const whiteColorsArray = ['#fff', '#ffffff', 'white'] as const
export const BackgroundColorText: FunctionComponent<BackgroundColorTextProps> = ({
	backgroundColor,
	isOutline,
	isOpenCircle,
	isClosedCircleDown,
	isOverlappedCircleUp,
	borderColor,
	children,
}) => {
	const backgroundCodeColor = whiteColorsArray.some((value) => value === backgroundColor)
		? 'transparent'
		: backgroundColor

	const wrapperRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(wrapperRef, {
		threshold: 0.9,
	})

	const platform = usePlatform()

	if (isOutline) {
		return (
			<div ref={wrapperRef} className={clsx(style.outline_wrapper)}>
				<span>{children}</span>
				<svg viewBox="0 0 346 126" preserveAspectRatio="none">
					<path
						className={clsx(isInView && style.is_inView, style.is_Reset)}
						stroke={borderColor || 'red'}
						strokeLinecap="round"
						fill="none"
						d="M126.911 2.03005C18.2568 32.1 -101.804 129.153 161.437 122.872C490.489 115.02 316.937 -43.0751 89.5011 30.3274"
					/>
				</svg>
			</div>
		)
	}

	if (isOpenCircle) {
		return (
			<div ref={wrapperRef} className={clsx(style.outline_wrapper)}>
				<span>{children}</span>
				<svg viewBox="0 0 312 132" fill="none" preserveAspectRatio="none">
					<path
						className={clsx(isInView && style.is_inView, style.is_Reset)}
						d="M35.6309 2.79713C-7.42177 53.5306 -48.2477 137.269 178.857 129.057C462.738 118.792 228.837 -24.4498 12.7613 13.3307"
						stroke={borderColor || 'red'}
						strokeLinecap="round"
						fill="none"
					/>
				</svg>
			</div>
		)
	}

	if (isClosedCircleDown) {
		return (
			<div ref={wrapperRef} className={clsx(style.outline_wrapper)}>
				<span>{children}</span>
				<svg viewBox="0 0 421 98" fill="none" preserveAspectRatio="none">
					<path
						className={clsx(isInView && style.is_inView, style.is_Reset)}
						d="M21.1286 80.3504C25.8496 82.0772 31.0392 82.9718 36.0152 83.8895C59.133 88.153 82.7051 90.0972 106.234 91.7699C182.581 97.1974 261.697 99.0784 337.359 87.0511C358.15 83.7461 380.203 79.4638 399.094 70.9127C409.828 66.0543 422.651 57.144 418.031 45.1952C413.158 32.5908 394.594 26.0991 381.636 21.8843C355.359 13.3381 327.161 9.20818 299.376 6.40665C244.445 0.867987 187.564 0.529353 132.559 5.51008C109.481 7.59981 86.5409 10.8279 64.0917 15.9386C47.4057 19.7373 28.7643 24.2809 14.9988 33.7757C0.653181 43.6708 -4.19961 58.8566 12.8096 68.8365C31.5553 79.8352 55.927 83.577 77.829 86.3904C105.209 89.9075 132.476 90.377 160.034 91.3924"
						stroke={borderColor || 'red'}
						strokeLinecap="round"
						fill="none"
					/>
				</svg>
			</div>
		)
	}

	if (isOverlappedCircleUp) {
		return (
			<div ref={wrapperRef} className={clsx(style.outline_wrapper)}>
				<span>{children}</span>
				<svg viewBox="0 0 256 95" fill="none" preserveAspectRatio="none">
					<path
						className={clsx(isInView && style.is_inView, style.is_Reset)}
						d="M13.5597 77.8498C16.4127 79.5216 19.5489 80.3875 22.5559 81.276C36.5264 85.4034 50.7714 87.2856 64.9903 88.9049C111.128 94.1592 158.939 95.9801 204.663 84.3367C217.227 81.1372 230.555 76.9916 241.971 68.7134C248.457 64.01 256.206 55.3841 253.414 43.8167C250.47 31.6145 239.251 25.33 231.42 21.2497C215.541 12.9763 198.5 8.97814 181.709 6.26602C148.514 0.904115 114.139 0.576289 80.899 5.39806C66.9525 7.4211 53.0895 10.5461 39.5231 15.4938C29.4394 19.1713 18.1741 23.5698 9.85542 32.7616C1.1861 42.3408 -1.74653 57.042 8.53245 66.7034C19.8608 77.3511 34.589 80.9735 47.8247 83.6971C64.3707 87.1019 80.8485 87.5564 97.5023 88.5394"
						stroke={borderColor || 'red'}
						strokeLinecap="round"
						fill="none"
					/>
				</svg>
			</div>
		)
	}

	return (
		<span
			className={clsx(
				style.wrapper,
				isOutline && style.is_outline,
				platform && style[`is_platform_${platform}`]
			)}
			style={{
				'--BackgroundColorText-borderColor': borderColor,
				'--BackgroundColorText-backgroundColor': backgroundCodeColor,
				'--BackgroundColorText-spacingInline':
					backgroundCodeColor === 'transparent' ? '0' : '1.25rem',
				'--BackgroundColorText-spacingInlineMobile':
					backgroundCodeColor === 'transparent' ? '0' : '.6875rem',
			}}>
			{children}
		</span>
	)
}
