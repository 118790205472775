const arrowUp = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
			<path
				d="M5.5472 1.98247L9.7122 6.15047C9.78506 6.22376 9.87169 6.28192 9.96711 6.32161C10.0625 6.3613 10.1649 6.38173 10.2682 6.38173C10.3715 6.38173 10.4739 6.3613 10.5693 6.32161C10.6647 6.28192 10.7513 6.22376 10.8242 6.15047C10.9707 6.00189 11.0528 5.80163 11.0528 5.59297C11.0528 5.38431 10.9707 5.18404 10.8242 5.03547L6.1052 0.312468C5.96193 0.169594 5.76909 0.0874683 5.56681 0.0831842C5.36452 0.0789001 5.16838 0.152786 5.0192 0.289467L0.264196 5.03747C0.183987 5.10875 0.119204 5.19569 0.0738266 5.29293C0.0284497 5.39017 0.00343868 5.49566 0.000330279 5.60293C-0.00277812 5.71019 0.0160817 5.81695 0.0557512 5.91666C0.0954206 6.01637 0.155061 6.10691 0.231008 6.18272C0.306954 6.25853 0.397602 6.318 0.497379 6.35749C0.597157 6.39698 0.703956 6.41565 0.811213 6.41235C0.91847 6.40905 1.02392 6.38385 1.12108 6.3383C1.21824 6.29275 1.30506 6.2278 1.3762 6.14747L5.5472 1.98247Z"
				fill="black"
			/>
		</svg>
	)
}

export default arrowUp
