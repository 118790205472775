import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { HeaderProps } from './Header'
import { SubNavigationLink } from './SubNavigationLink'
import styles from './SubNavigationLogin.module.sass'

export type SubNavigationLoginProps = NonNullable<
	NonNullable<HeaderProps['header']['login']>['subMenu']
>

export const SubNavigationLogin: FunctionComponent<SubNavigationLoginProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				{items.map((item) => (
					<li key={item.id} className={clsx(styles.item, item.icon?.icon && styles.has_icon)}>
						<SubNavigationLink type="login" {...item} />
					</li>
				))}
			</ul>
		</div>
	)
}
