const arrowDown = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
			<path
				d="M5.51436 5.18539L9.67936 1.01739C9.75222 0.944104 9.83886 0.885942 9.93428 0.846253C10.0297 0.806565 10.132 0.786133 10.2354 0.786133C10.3387 0.786133 10.441 0.806565 10.5365 0.846253C10.6319 0.885942 10.7185 0.944104 10.7914 1.01739C10.9379 1.16597 11.02 1.36624 11.02 1.57489C11.02 1.78355 10.9379 1.98382 10.7914 2.13239L6.07236 6.85539C5.9291 6.99827 5.73626 7.08039 5.53398 7.08468C5.33169 7.08896 5.13555 7.01508 4.98636 6.8784L0.231364 2.13939C0.0835058 1.99193 0.00028202 1.79178 7.15433e-07 1.58296C-0.000280589 1.37413 0.082404 1.17375 0.229864 1.02589C0.377325 0.878036 0.577482 0.794812 0.786304 0.794531C0.995126 0.79425 1.19551 0.876934 1.34336 1.02439L5.51436 5.18539Z"
				fill="black"
			/>
		</svg>
	)
}

export default arrowDown
