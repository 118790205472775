import type { FunctionComponent } from 'react'
import type { HeaderProps } from './Header'
import styles from './SubNavigation.module.sass'
import { SubNavigationGroups } from './SubNavigationGroups'
import { SubNavigationLogin } from './SubNavigationLogin'
import { SubNavigationSpecialGroup } from './SubNavigationSpecialGroup'

export type SubNavigationProps = SubNavigationMainProps | SubNavigationLoginProps

type SubNavigationMainProps = {
	type: 'main'
} & NonNullable<HeaderProps['header']['links']>['items'][number]

type SubNavigationLoginProps = {
	type: 'login'
} & NonNullable<HeaderProps['header']['login']>['subMenu']

export const SubNavigation: FunctionComponent<SubNavigationProps> = (props) => {
	return (
		<div className={styles.wrapper}>
			{props.type === 'main' ? (
				<>
					{props.groups?.items && props.groups?.items.length > 0 && (
						<div className={styles.groups}>
							<SubNavigationGroups groups={props.groups} />
						</div>
					)}
					{(props.specialGroup?.links?.items.length ?? 0) > 0 && (
						<div className={styles.specialGroup}>
							<SubNavigationSpecialGroup specialGroup={props.specialGroup} />
						</div>
					)}
				</>
			) : (
				<div className={styles.login}>
					<SubNavigationLogin items={props.items} />
				</div>
			)}
		</div>
	)
}
