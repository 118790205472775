// Reexport all svg icons

export { default as fourthPtStar } from './4ptStar.svg'
export { default as arch } from './arch.svg'
export { default as arrow } from './arrow.svg'
export { default as ArrowDown } from './arrowDown'
export { default as ArrowUp } from './arrowUp'
export { default as ball } from './ball.svg'
export { default as blankCanvas } from './blank-canvas.svg'
export { default as cerosLogo } from './cerosLogo.svg'
export { default as chaChing } from './cha-ching.svg'
export { default as chatLogo } from './chatLogo.svg'
export { default as checkFalse } from './check-false.svg'
export { default as checkGemma } from './check-gemma.svg'
export { default as checkTrue } from './check-true.svg'
export { default as checkmark } from './checkmark.svg'
export { default as chevronRight } from './chevron-right.svg'
export { default as cikica } from './cikica.svg'
export { default as circle } from './circle.svg'
export { default as close } from './close.svg'
export { default as clover } from './clover.svg'
export { default as cloverV2 } from './cloverV2.svg'
export { default as cross } from './cross.svg'
export { default as cursor } from './cursor.svg'
export { default as curvedSquare } from './curvedSquare.svg'
export { default as damn } from './damn.svg'
export { default as desktop } from './desktop.svg'
export { default as doiley } from './doiley.svg'
export { default as dojaDogCursos } from './doja-dog-cursor.svg'
export { default as dwightCursor } from './dwight-cursor.svg'
export { default as education } from './education.svg'
export { default as exploreTheSuite } from './exploreTheSuite.svg'
export { default as eye } from './eye.svg'
export { default as flower } from './flower.svg'
export { default as fourTipStar } from './fourTipStar.svg'
export { default as gemmaLogo } from './gemmaLogo.svg'
export { default as grandSlamBaby } from './grand-slam-baby.svg'
export { default as gummyBear } from './gummyBear.svg'
export { default as hamburger } from './hamburger.svg'
export { default as home } from './home.svg'
export { default as hotspot } from './hotspot.svg'
export { default as image } from './image.svg'
export { default as import } from './import.svg'
export { default as InstagramIcon } from './instagram'
export { default as layout } from './layout.svg'
export { default as LinkedInIcon } from './linkedin'
export { default as logo } from './logo.svg'
export { default as magicPen } from './magic-pen.svg'
export { default as mobile } from './mobile.svg'
export { default as pages } from './pages.svg'
export { default as pinkPantherCursor } from './pink-panther-cursor.svg'
export { default as playButton } from './playButton.svg'
export { default as quadrant } from './quadrant.svg'
export { default as rainbow } from './rainbow.svg'
export { default as rectangle } from './rectangle.svg'
export { default as share } from './share.svg'
export { default as shoulderBrushing } from './shoulder-brushing.svg'
export { default as sixtuplet } from './sixtuplet.svg'
export { default as splashStar } from './splashStar.svg'
export { default as splashStarOutline } from './splashStarOutline.svg'
export { default as star } from './star.svg'
export { default as fourthPtStarV2, default as starV2 } from './starV2.svg'
export { default as text } from './text.svg'
export { default as thumbUp } from './thumbUp.svg'
export { default as tools } from './tools.svg'
export { default as triangle, default as trinagle } from './triangle.svg'
export { default as triangleOutline } from './triangleOutline.svg'
export { default as TwitterIcon } from './twitter'
export { default as wave } from './wave.svg'
