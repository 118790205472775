import type { FunctionComponent } from 'react'
import { Container } from './Container'
import styles from './SelfPromotion.module.sass'
import { Wysiwyg } from './Wysiwyg'

export interface SelfPromotionProps {
	content: string
}

export const SelfPromotion: FunctionComponent<SelfPromotionProps> = ({ content }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<Wysiwyg source={content} />
			</Container>
		</div>
	)
}
