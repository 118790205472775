export const scrollToTargetToTopElement = (targetId: string) => {
	const scrollToTargetElement = document.getElementById(targetId)

	if (!scrollToTargetElement) {
		console.warn('Scroll to element is not found')
		return
	}

	return scrollToTargetElement.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	})
}
