import clsx from 'clsx'
import {
	Dispatch,
	FunctionComponent,
	MouseEventHandler,
	SetStateAction,
	useCallback,
	useRef,
} from 'react'
import { LinkType } from '../../generated/content'
import type { HeaderResult } from '../data/content/HeaderFragment'
import type { ImageResult } from '../data/content/ImageFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { isDefined } from '../utilities/isDefined'
import useOnClickOutside from '../utilities/useOnClickOutside'
import { Button } from './Button'
import styles from './HeaderMobile.module.sass'
import { Icon } from './Icon'
import { ImageSizeByArea } from './ImageSizeByArea'
import { Link } from './Link'
import { useTheme } from './ThemeProvider'

export type HeaderMobileProps = HeaderResult & {
	setOpenMobileMenu: Dispatch<SetStateAction<boolean>>
	isOpenMobileMenu: boolean
	handleClick?: MouseEventHandler<HTMLDivElement>
}

export const HeaderMobile: FunctionComponent<HeaderMobileProps> = ({
	links,
	specialLink,
	login,
	setOpenMobileMenu,
	isOpenMobileMenu,
	handleClick,
}) => {
	const ref = useRef(null)
	const theme = useTheme()

	const handleClose = useCallback(() => {
		setOpenMobileMenu(false)
	}, [setOpenMobileMenu])

	useOnClickOutside(ref, handleClose)
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.main, isOpenMobileMenu && styles.is_open)} ref={ref}>
				<div className={styles.buttonWrapper}>
					<button
						type="button"
						className={clsx(styles.button, styles.is_hamburger)}
						onClick={() => {
							setOpenMobileMenu(true)
						}}
						aria-label="Open navigation">
						<Icon name="hamburger" />
					</button>
				</div>
				<div className={styles.menu}>
					<button
						type="button"
						className={clsx(styles.button, styles.is_close)}
						onClick={() => handleClose()}
						aria-label="Close navigation">
						<Icon name="close" />
					</button>
					<div className={styles.sections}>
						{links?.items &&
							links.items.map((primaryItem) => (
								// eslint-disable-next-line
								<div key={primaryItem.id} className={styles.section} onClick={handleClick}>
									<div
										className={
											primaryItem.link?.title === 'Products'
												? styles.productsWrapper
												: styles.sectionIn
										}>
										<Link link={primaryItem.link} className={styles.primaryLink} />
										<div
											className={
												primaryItem.link?.title === 'Products' ? styles.products : styles.groups
											}>
											{primaryItem.groups?.items &&
												primaryItem.groups.items.map(
													(group) =>
														group.links?.items && (
															<HeaderMobileGroup
																key={group.id}
																items={group.links?.items
																	.map(
																		(item) =>
																			item && {
																				id: item.id,
																				link: item.link,
																				icon: item.icon?.icon,
																				description: item.description,
																			}
																	)
																	.filter(isDefined)}
															/>
														)
												)}
										</div>
									</div>
								</div>
							))}
						{/* eslint-disable-next-line */}
						{/* <div className={styles.section} onClick={handleClick}>
							<div className={styles.sectionIn}>
								<Link
									link={login?.link}
									className={
										login?.link?.title === 'Login' ? styles.productLoginLink : styles.primaryLink
									}
								/>
								<div className={styles.groups}>
									{login?.subMenu?.items && (
										<HeaderMobileGroup
											items={login.subMenu.items
												.map(
													(item) =>
														item && {
															id: item.id,
															link: item.link,
															icon: item.icon?.icon,
															description: item.description,
														}
												)
												.filter(isDefined)}
										/>
									)}
								</div>
							</div>
						</div> */}
						{specialLink && (
							<div className={clsx(styles.section, styles.actions)}>
								{specialLink && (
									<Button
										type="link"
										href={contemberLinkToHref(specialLink)}
										fill="blue"
										target={
											specialLink.type !== LinkType.scrollToTarget && specialLink.isTargetBlank
												? '_blank'
												: undefined
										}>
										{specialLink.title}
									</Button>
								)}
								<Button
									type="link"
									href={contemberLinkToHref(login?.link as any)}
									outline
									fill={theme.primary === 'light' ? 'light' : 'transparent'}
									target={
										specialLink.type !== LinkType.scrollToTarget && specialLink.isTargetBlank
											? '_blank'
											: undefined
									}>
									{login?.link?.title}
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

type HeaderMobileGroupProps = {
	items: HeaderMobileGroupsItemProps[]
}

type HeaderMobileGroupsItemProps = {
	id: string
	icon?: ImageResult
	link?: LinkResult
	description?: string
}

const HeaderMobileGroup: FunctionComponent<HeaderMobileGroupProps> = ({ items }) => {
	const iconMaxArea = 18

	return (
		<ul className={styles.group}>
			{items.map((item) => (
				<li key={item.id} className={styles.groupItem}>
					<Link link={item.link}>
						<div
							className={clsx(styles.groupItemContent, item.icon && styles.view_icon)}
							style={{
								'--HeaderMobile-icon-size': `${iconMaxArea}px`,
							}}>
							{item.icon && <ImageSizeByArea image={item.icon} imageMaxArea={iconMaxArea} />}
							{item.link?.title}
						</div>
					</Link>
				</li>
			))}
		</ul>
	)
}
