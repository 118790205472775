const LinkedInIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<g clipPath="url(#clip0_2250_33853)">
				<path
					d="M0.200073 2.79788C0.200073 2.12219 0.436567 1.56476 0.909534 1.12558C1.3825 0.686385 1.99738 0.466797 2.75413 0.466797C3.49738 0.466797 4.09872 0.683 4.55819 1.11545C5.03116 1.56139 5.26765 2.14246 5.26765 2.85869C5.26765 3.50734 5.03793 4.04787 4.57846 4.48032C4.10549 4.92626 3.48386 5.14924 2.71359 5.14924H2.69332C1.95007 5.14924 1.34873 4.92626 0.889264 4.48032C0.429797 4.03437 0.200073 3.47355 0.200073 2.79788ZM0.463587 20.5344V6.99383H4.9636V20.5344H0.463587ZM7.45684 20.5344H11.9569V12.9736C11.9569 12.5006 12.0109 12.1357 12.119 11.879C12.3082 11.4195 12.5954 11.031 12.9805 10.7134C13.3656 10.3959 13.8487 10.2371 14.4298 10.2371C15.9434 10.2371 16.7001 11.2573 16.7001 13.2979V20.5344H21.2001V12.7709C21.2001 10.7709 20.7271 9.25397 19.7812 8.22019C18.8352 7.1864 17.5852 6.66951 16.0312 6.66951C14.2879 6.66951 12.9298 7.41951 11.9569 8.91951V8.96005H11.9366L11.9569 8.91951V6.99383H7.45684C7.48386 7.42626 7.49738 8.77085 7.49738 11.0276C7.49738 13.2844 7.48386 16.4533 7.45684 20.5344Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2250_33853">
					<rect width="21" height="21" fill="white" transform="translate(0.200073 0.466797)" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default LinkedInIcon
