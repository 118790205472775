import { LinkType } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import { multisite } from './multisite'

export const contemberLinkToHref = (link: LinkResult): string => {
	if (
		(link.type === LinkType.external || link.type === LinkType.scrollToTarget) &&
		link.externalLink !== undefined &&
		link.externalLink !== null
	) {
		return link.externalLink
	}
	if (link.type === LinkType.internal && link.internalLink !== undefined) {
		return multisite.realUrl(link.internalLink.url)
	}
	console.warn('Invalid link', link)
	return ''
}
