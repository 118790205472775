import type { FunctionComponent } from 'react'
import type { HeaderLinkSubGroupResult } from '../data/content/HeaderLinkSubGroupFragment'
import { SubNavigationLink } from './SubNavigationLink'
import styles from './SubNavigationSpecialGroup.module.sass'

export type SubNavigationSpecialGroupProps = {
	specialGroup?: HeaderLinkSubGroupResult
}

export const SubNavigationSpecialGroup: FunctionComponent<SubNavigationSpecialGroupProps> = ({
	specialGroup,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<ul className={styles.list}>
					{specialGroup?.links?.items.map((item) => (
						<li key={item.id} className={styles.item}>
							<SubNavigationLink {...item} />
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
