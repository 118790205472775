/* eslint-disable prettier/prettier */
import { RefObject, useEffect, useState } from 'react'
import { useIntersection } from 'react-use'

export const useIsInView = (ref: RefObject<HTMLElement>, options: IntersectionObserverInit, once = false) => {
	const [isInView, setIsInView] = useState(false)

	const intersection = useIntersection(ref, options)

	useEffect(() => {
		if (once) {
			if (intersection?.isIntersecting) {
				setIsInView(intersection?.isIntersecting)
			}
		} else {
			setIsInView(intersection?.isIntersecting ?? false)
		}
	}, [intersection?.isIntersecting, isInView, once])

	return isInView
}
