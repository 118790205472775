import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import styles from './AddTopTooltip.module.sass'

export type AddTopTooltipProps = {
	children: React.ReactNode
	content: string
	backgroundColor: string
	textColor: string
	position: string
}

export type AddTopTooltipElement = {
	type: 'addTooltipTop'
	suchThat: {
		content: string
		backgroundColor: string
		textColor: string
		position: string
	}
}

export const AddTopTooltip: FunctionComponent<AddTopTooltipProps> = ({
	content,
	backgroundColor,
	textColor,
	children,
}) => {
	return (
		<div className={clsx(styles.outline_wrapper)}>
			<span className={styles.text}>{children}</span>
			<div className={clsx(styles.tooltip_wrapper)} style={{ backgroundColor: backgroundColor }}>
				<span style={{ color: textColor }}>{content}</span>
			</div>
		</div>
	)
}
