import { RichTextRenderer } from '@contember/react-client'
import { AddArrowBelowText, AddArrowBelowTextElement } from './AddArrowBelowText'
import { AddTooltip, AddTooltipElement } from './AddTooltip'
import { AddTopTooltip } from './AddTopTooltip'
import { BackgroundColorText } from './BackgroundColorText'
import { ChangeTextToShape, ChangeTextToShapeElement } from './ChangeTextToShape'
import { HighlightCodeColor } from './HighlightCodeColor'

export type HighlightColorElement = {
	type: 'highlightCodeColor'
	suchThat: { fillColor?: string; backgroundColor?: string }
}

export type ChangeTextColorElement = {
	type: 'changeTextColor'
	suchThat: { color?: string }
}

export type BackgroundColorTextElement = {
	type: 'backgroundColorText'
	suchThat: {
		backgroundColor?: string
		isOutline?: boolean
		isOpenCircle?: boolean
		isClosedCircleDown?: boolean
		isOverlappedCircleUp?: boolean
		borderColor?: string
	}
}

type CustomElements =
	| HighlightColorElement
	| BackgroundColorTextElement
	| ChangeTextToShapeElement
	| AddArrowBelowTextElement
	| AddTooltipElement
	| ChangeTextColorElement

export const CerosRichTextRenderer: typeof RichTextRenderer = (props) => {
	return (
		<RichTextRenderer
			{...props}
			renderElement={(...args) => {
				const [params] = args
				const element = params.element as (typeof params)['element'] | CustomElements
				switch (element.type) {
					case 'highlightCodeColor':
						const elementHighlighCodeColor = element as HighlightColorElement
						return (
							<HighlightCodeColor
								fillColor={elementHighlighCodeColor.suchThat.fillColor}
								backgroundColor={elementHighlighCodeColor.suchThat.backgroundColor}>
								{params.children}
							</HighlightCodeColor>
						)
					case 'backgroundColorText':
						const elementBackgroundColorText = element as BackgroundColorTextElement
						return (
							<BackgroundColorText
								isOutline={elementBackgroundColorText.suchThat.isOutline}
								isOpenCircle={elementBackgroundColorText.suchThat.isOpenCircle}
								isClosedCircleDown={elementBackgroundColorText.suchThat.isClosedCircleDown}
								isOverlappedCircleUp={elementBackgroundColorText.suchThat.isOverlappedCircleUp}
								borderColor={elementBackgroundColorText.suchThat.borderColor}
								backgroundColor={elementBackgroundColorText.suchThat.backgroundColor}>
								{params.children}
							</BackgroundColorText>
						)
					case 'changeTextToShape':
						const elementChangeTextToShape = element as ChangeTextToShapeElement

						return (
							<>
								<ChangeTextToShape
									shapeColor={elementChangeTextToShape.suchThat.shapeColor as string}
									shape={elementChangeTextToShape.suchThat.shape}>
									{params.children}
								</ChangeTextToShape>
							</>
						)
					case 'addArrowBelowText':
						const addArrowBelowText = element as AddArrowBelowTextElement

						return (
							<>
								<AddArrowBelowText
									shape={addArrowBelowText.suchThat.shape}
									iconColor={addArrowBelowText.suchThat.iconColor}
									textColor={addArrowBelowText.suchThat.textColor}>
									{params.children}
								</AddArrowBelowText>
							</>
						)
					case 'addTooltip':
						const addTooltip = element as AddTooltipElement

						return (
							<>
								<AddTooltip
									position={addTooltip.suchThat.position}
									content={addTooltip.suchThat.content}
									backgroundColor={addTooltip.suchThat.backgroundColor}
									textColor={addTooltip.suchThat.textColor}>
									{params.children}
								</AddTooltip>
							</>
						)
					case 'addTooltipTop':
						const addTooltipTop = element as any

						return (
							<>
								<AddTopTooltip
									position={addTooltipTop.suchThat.position}
									content={addTooltipTop.suchThat.content}
									backgroundColor={addTooltipTop.suchThat.backgroundColor}
									textColor={addTooltipTop.suchThat.textColor}>
									{params.children}
								</AddTopTooltip>
							</>
						)
					case 'changeTextColor':
						const changeTextColor = element as ChangeTextColorElement
						return (
							<span style={{ color: changeTextColor.suchThat.color || '#000000' }}>
								{params.children}
							</span>
						)

					case 'pointingArrow':
						return <span></span>

					case 'anchor':
						const anchor = element as any

						return (
							<a href={anchor.href} target="_blank">
								{anchor?.children[0]?.text}
							</a>
						)
				}

				const { renderElement } = props
				if (renderElement && typeof renderElement === 'function') {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					return (renderElement as any)(...args)
				}
				return params.fallback
			}}
		/>
	)
}
