import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { scrollToTargetToTopElement } from '../utilities/scrollToTargetToTopElement'

export type LinkProps = {
	className?: string
	activeClassName?: string
	children?: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & ({ link: LinkResult | undefined } | { href: string })

export function Link(props: LinkProps) {
	const activeUrl = useActivePage()

	if ('href' in props) {
		const url = props.href
		const isActive = activeUrl === url
		return (
			<NextLink
				href={url}
				className={clsx(props.className, isActive && props.activeClassName)}
				onClick={props.onClick}>
				{props.children}
			</NextLink>
		)
	}
	if (props.link?.externalLink || props.link?.internalLink) {
		const url = contemberLinkToHref(props.link)

		const isActive = activeUrl === url
		return (
			<NextLink
				href={url}
				className={clsx(props.className, isActive && props.activeClassName)}
				onClick={
					props.onClick ??
					(props.link.type === LinkType.scrollToTarget
						? (event) => {
								if (!props.link?.externalLink) {
									console.error(`Scroll to target ID is missing`)
									return
								}
								event.preventDefault()
								const targetId = props.link.externalLink.startsWith('#')
									? props.link.externalLink.substring(1)
									: props.link.externalLink
								scrollToTargetToTopElement(targetId)
						  }
						: undefined)
				}
				target={props.link.isTargetBlank ? '_blank' : undefined}
				rel={props.link.isTargetBlank ? 'noopener noreferrer' : undefined}>
				{props.children ?? props.link.title}
			</NextLink>
		)
	} else {
		return null
	}
}
