import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import style from './HighlightCodeColor.module.sass'

export const emptyColorsArray: string[] = []
export const whiteColorsArray: string[] = ['#fff', '#ffffff', 'white']

export type HighlightCodeColorProps = {
	fillColor?: string
	backgroundColor?: string
	children: React.ReactNode
}

export const HighlightCodeColorTransparentBackgroundColor =
	React.createContext<string[]>(whiteColorsArray)

export const HighlightCodeColorPreset: FunctionComponent<{
	children: React.ReactNode
	transparentWhite: boolean
}> = ({ children, transparentWhite }) => {
	return (
		<HighlightCodeColorTransparentBackgroundColor.Provider
			value={transparentWhite ? whiteColorsArray : emptyColorsArray}>
			{children}
		</HighlightCodeColorTransparentBackgroundColor.Provider>
	)
}

export const HighlightCodeColor: FunctionComponent<HighlightCodeColorProps> = ({
	fillColor,
	backgroundColor,
	children,
}) => {
	const transparentColors = React.useContext(HighlightCodeColorTransparentBackgroundColor)
	const backgroundCodeColor =
		transparentColors.indexOf(backgroundColor ?? 'transparent') > -1
			? 'transparent'
			: backgroundColor

	const hasBackground = backgroundCodeColor && backgroundCodeColor !== 'transparent'

	return (
		<code
			className={clsx(style.wrapper, hasBackground && style.hasBackground)}
			style={{
				'--HighlightColor-fillColor': fillColor,
				'--HighlightColor-backgroundColor': backgroundCodeColor,
			}}>
			{children}
		</code>
	)
}
