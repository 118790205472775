import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { LinkSubGroupsResult } from '../data/content/HeaderLinkSubGroupsFragment'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { ContemberImage } from './ContemberImage'
import styles from './SubNavigationGroups.module.sass'
import { SubNavigationLink } from './SubNavigationLink'

export type SubNavigationGroupsProps = {
	groups?: LinkSubGroupsResult
}

export const SubNavigationGroups: FunctionComponent<SubNavigationGroupsProps> = ({ groups }) => {
	const colors: Record<any, any> = {
		green: '#DFFA9E',
		purple: '#F0E6FF',
		blue: '#E2F1FD',
		pink: '#FCE5EF',
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				{groups?.items.map((group: any) => (
					<div key={group.id} className={styles.header}>
						{group.name && (
							<div className={styles.groupTitle}>
								<CerosRichTextRenderer source={group.name} />
								{group.icon && <ContemberImage image={group.icon?.icon as any} />}
							</div>
						)}
					</div>
				))}
			</div>
			<div className={styles.main}>
				{groups?.items.map((group, i) => {
					// @ts-ignore
					let unique = groups?.items?.at(0)?.icon?.icon?.fileName === 'Products.svg'

					return (
						<div key={group.id} className={styles.group}>
							<ul className={clsx(styles.list, unique === true && styles.productsList)}>
								{group.links?.items &&
									group.links.items.length > 0 &&
									group.links.items.map((item) => {
										return (
											<li
												style={{ backgroundColor: colors[item.backgroundColor as any] }}
												key={item.id}
												className={clsx(
													styles.item,
													item.icon?.icon && styles.has_icon,
													unique === true && styles.itemSecondary
												)}>
												<SubNavigationLink {...item} />
											</li>
										)
									})}
							</ul>
						</div>
					)
				})}
			</div>
		</div>
	)
}
