import clsx from 'clsx'
import { usePauseHidingHeader, useRunHidingHeader } from 'hiding-header-react'
import {
	FunctionComponent,
	MouseEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import type { HeaderResult } from '../data/content/HeaderFragment'
import type { HeaderGemmaResult } from '../data/content/HeaderGemmaFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { isSpecialKeyPressed } from '../utilities/isSpecialKeyPressed'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { GemmaHeader } from './GemmaHeader'
import styles from './Header.module.sass'
import { HeaderMobile } from './HeaderMobile'
import { Link } from './Link'
import { SubNavigation } from './SubNavigation'
import { useTheme } from './ThemeProvider'

export type HeaderProps = {
	headerGemma?: HeaderGemmaResult | null
	header: HeaderResult
	isHome: boolean
}

const primaryNavigationItemHasSubmenu = (
	item: NonNullable<HeaderProps['header']['links']>['items'][number]
) => item.groups || item.specialGroup

export const Header: FunctionComponent<HeaderProps> = ({ headerGemma, header, isHome }) => {
	const runHidingHeader = useRunHidingHeader()
	const pauseHidingHeader = usePauseHidingHeader()
	const [count, setCount] = useState(0)
	const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)

	useEffect(() => {
		if (isOpenMobileMenu) {
			pauseHidingHeader && pauseHidingHeader()
		} else {
			runHidingHeader && runHidingHeader()
		}
	}, [runHidingHeader, pauseHidingHeader, isOpenMobileMenu])

	const handleClick: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
		if (event.target instanceof HTMLElement && event.target.closest('a')) {
			setOpenMobileMenu(false)
			setCount((previousCount) => previousCount + 1)
		}
	}, [])

	const headerLinksItems = useMemo(() => header.links?.items ?? [], [header.links?.items])
	const theme = useTheme()

	const activePage = useActivePage()

	return (
		<>
			<header
				className={clsx(
					styles.wrapper,
					isHome && styles.is_home,
					styles[`is_theme_${theme.primary}`]
				)}
				key={
					count // if the value (count) in key changes, the header renderers again (so the effect is that it hides the sub-navigation which is displayed on native hover)
				}>
				<Container size="wide">
					<div className={styles.header}>
						{headerGemma ? (
							<GemmaHeader {...headerGemma} />
						) : (
							<>
								<h1 className={styles.logo}>
									<Link href="/" className={styles.logo_in}>
										{header.logo && (
											<ContemberImage image={{ ...header.logo, alt: header.logo.alt || 'logo' }} />
										)}
									</Link>
								</h1>
								<div className={styles.links}>
									{headerLinksItems.map(
										(item, index) =>
											item.link && (
												// eslint-disable-next-line
												<div key={item.id} className={styles.linksItem} onClick={handleClick}>
													{/* eslint-disable-next-line */}
													<Link
														link={item.link}
														className={styles.link}
														activeClassName={styles.is_active}
														onClick={(event) => {
															if (
																isSpecialKeyPressed(event.nativeEvent) ||
																!primaryNavigationItemHasSubmenu(item)
															) {
																return
															}
															event.preventDefault()
														}}
													/>
													{primaryNavigationItemHasSubmenu(item) && (
														<>
															<div className={styles.peakShape}></div>
															<div
																className={clsx(
																	styles.subNavigation,
																	styles.is_desktop,
																	index === 0 && styles.is_first,
																	index + 1 === headerLinksItems.length && styles.is_last
																)}>
																<SubNavigation type="main" {...item} />
															</div>
														</>
													)}
												</div>
											)
									)}
								</div>
								<div className={styles.column}>
									<div className={styles.actions}>
										{header.login && (
											<div className={styles.is_desktop}>
												<HeaderLogin
													link={header.login.link}
													subMenu={header.login.subMenu}
													buttonText={header.login.link?.title}
												/>
											</div>
										)}
										{header.specialLink && (
											<>
												<div className={styles.is_desktop}>
													<Button
														type="link"
														href={
															activePage.startsWith('/gemma/')
																? 'https://web.gemma.ai/signup'
																: contemberLinkToHref(header.specialLink)
														}
														fill={theme.primary === 'light' ? 'blue' : 'light'}
														target={
															header.specialLink.isTargetBlank &&
															header.specialLink.type !== LinkType.scrollToTarget
																? '_blank'
																: undefined
														}>
														{activePage.startsWith('/gemma/')
															? 'Sign up now'
															: header.specialLink.title}
													</Button>
												</div>
												<div className={styles.is_mobile}>
													{/* <Link link={header.specialLink} className={styles.specialLink} /> */}
													<Button
														type="link"
														href={
															activePage.startsWith('/gemma/')
																? 'https://web.gemma.ai/signup'
																: contemberLinkToHref(header.specialLink)
														}
														fill={theme.primary === 'light' ? 'blue' : 'light'}
														target={
															header.specialLink.isTargetBlank &&
															header.specialLink.type !== LinkType.scrollToTarget
																? '_blank'
																: undefined
														}>
														{activePage.startsWith('/gemma/')
															? 'Sign up now'
															: header.specialLink.title}
													</Button>
												</div>
												<div style={{ width: '.25rem' }}></div>
											</>
										)}
									</div>
									<div className={styles.mobileHeader}>
										<HeaderMobile
											{...header}
											isOpenMobileMenu={isOpenMobileMenu}
											setOpenMobileMenu={setOpenMobileMenu}
											handleClick={handleClick}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</Container>
			</header>
			<div className={styles.gap} />
		</>
	)
}

type HeaderLoginProps = HeaderProps['header']['login'] & {
	buttonText?: string
}

export const HeaderLogin: FunctionComponent<HeaderLoginProps> = ({ link, subMenu, buttonText }) => {
	const theme = useTheme()

	return (
		<div className={styles.login}>
			{link && (
				<Button
					type="link"
					href={contemberLinkToHref(link)}
					fill={theme.primary === 'light' ? 'light' : 'transparent'}
					outline
					// onClick={(event) => {
					// 	if (isSpecialKeyPressed(event.nativeEvent) || !subMenu?.items) {
					// 		return
					// 	}
					// 	event.preventDefault()
					// }}
					target={
						link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
					}>
					{buttonText}
				</Button>
			)}
			{/* {subMenu?.items && (
				<div className={clsx(styles.subNavigation, styles.is_login, styles.is_desktop)}>
					<SubNavigation type="login" items={subMenu?.items} />
				</div>
			)} */}
		</div>
	)
}
