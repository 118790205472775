import { z } from 'zod'

export function getMultisiteConfig() {
	const config = z
		.record(z.string())
		.nullish()
		.parse(process.env.MULTISITES ? JSON.parse(process.env.MULTISITES) : null)

	const multisites = Object.entries(config ?? {}).sort((a, b) => b[1].length - a[1].length)

	const currentMultisite = z.string().nullish().parse(process.env.WEB_ROOT_URL)

	const currentLinkablePrefix = currentMultisite ? config?.[currentMultisite] : null

	return {
		multisites,
		config,
		currentMultisite,
		currentLinkablePrefix,
		linkableFilter: currentMultisite
			? {
					url: {
						startsWith: currentLinkablePrefix,
					},
					not: {
						or: multisites
							.filter((ms) => ms[0] !== currentMultisite)
							.filter((ms) => ms[1].length > 1)
							.map((ms) => ({ url: { startsWith: ms[1] } })),
					},
			  }
			: undefined,
		realUrl(url: string, requireDomain = false) {
			if (url.startsWith('/')) {
				for (const site of multisites) {
					if (url.startsWith(site[1])) {
						let path = url.slice(site[1].length)
						path = path.length === 0 ? '/' : path
						if (site[0] === currentMultisite && !requireDomain) {
							return path
						}
						return site[0] + path
					}
				}
			}
			return url
		},
		linkableUrl(url: string) {
			if (!currentLinkablePrefix) {
				return url
			}

			const result = `${currentLinkablePrefix}${url}`
			if (result.length > 1 && result.endsWith('/')) {
				return result.slice(0, -1)
			}
			return result
		},
	}
}
