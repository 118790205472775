import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import styles from './BreakableContent.module.sass'

export type BreakableContentProps = {
	breakableFrom?: 'always' | '768' | '992'
} & (BreakableTextProps | BreakableChildrenProps)

type BreakableTextProps = {
	text: string
}

type BreakableChildrenProps = {
	children: ReactNode
}

export const BreakableContent: FunctionComponent<BreakableContentProps> = ({
	breakableFrom = 'always',
	...props
}) => {
	return (
		<div className={clsx(styles.wrapper, breakableFrom && styles[`is_${breakableFrom}`])}>
			{'text' in props ? (
				<BreakableText text={props.text} />
			) : 'children' in props ? (
				props.children
			) : null}
		</div>
	)
}

export const BreakableText: FunctionComponent<BreakableTextProps> = ({ text }) => {
	const lines = text.split('\n')
	return (
		<>
			{lines.map((line, i) => (
				<React.Fragment key={i}>
					{i != 0 && <br />}
					{line}
				</React.Fragment>
			))}
		</>
	)
}
