import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useRef } from 'react'
import { useIsInView } from '../utilities/useIsInView'
import styles from './AddArrowBelowText.module.sass'

const defaultShapes = { arrow1: 'arrow1' } as const
type DefaultShapes = (typeof defaultShapes)[keyof typeof defaultShapes]

export type AddArrowBelowTextProps = {
	shape: DefaultShapes
	iconColor?: string
	textColor?: string
	children: React.ReactNode
}

export type AddArrowBelowTextElement = {
	type: 'addArrowBelowText'
	suchThat: { shape: DefaultShapes; iconColor?: string; textColor?: string }
}

export const AddArrowBelowText: FunctionComponent<AddArrowBelowTextProps> = ({
	shape,
	iconColor,
	textColor,
	children,
}) => {
	const { asPath } = useRouter()
	const wrapperRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(wrapperRef, {
		threshold: 0.9,
	})

	const isAboutPage = ['/about/'].includes(asPath)

	const renderSVG = (shape: string, iconColor: string, isInView: boolean) => {
		let svgPath

		switch (shape) {
			case 'arrow1':
				svgPath = (
					<svg viewBox="0 0 184 37" fill="none" style={{ bottom: '-45%', overflow: 'visible' }}>
						<path
							style={{ strokeWidth: isAboutPage ? '5.5px !important' : '4px !important' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2.37817 5.78068C60.8915 3.27985 178.722 -0.221306 181.938 5.78068C185.958 13.2832 -7.67183 6.6389 2.37817 15.9067C12.4282 25.1745 155 9.19657 129.008 34.4667"
							stroke={iconColor || 'red'}
							strokeWidth={isAboutPage ? 6 : 4}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow2':
				svgPath = (
					<svg viewBox="0 0 164 11" fill="none" style={{ bottom: '-30%' }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2 5.70032C16.1095 3.12641 40.1789 -0.47706 23.5807 5.70032C2.83294 13.4221 76.4345 -4.27197 50.6891 5.35109C24.9437 14.9741 109.979 -5.70767 88.3985 5.35109C66.8178 16.4098 147.083 -5.08682 126.714 5.35109C106.345 15.789 162 -0.042474 162 5.35109"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow3':
				svgPath = (
					<svg viewBox="0 0 206 34" fill="none" style={{ bottom: '-40%' }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2.17188 2.5415C84.6121 9.68622 238.954 25.2827 196.801 30.5107C144.109 37.0458 127.353 -12.1769 200.096 11.0601"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow4':
				svgPath = (
					<svg viewBox="0 0 270 32" fill="none" style={{ bottom: '-40%' }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2.23657 17.334C42.2625 12.9435 145.456 3.86167 238.024 2.65815C353.734 1.15374 100.269 12.6913 46.3169 25.1967C-7.63506 37.7021 248.495 16.6091 248.495 16.6091"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow5':
				svgPath = (
					<svg viewBox="0 0 313 21" fill="none" style={{ bottom: '-30%' }}>
						<g style={{ mixBlendMode: 'multiply' }}>
							<path
								style={{ strokeWidth: '4px' }}
								className={clsx(isInView && styles.is_inView, styles.is_Reset)}
								d="M2.95611 17.2465C24.7717 16.1411 46.5422 14.2231 68.3442 12.9096C131.224 9.12131 194.164 6.46244 257.129 4.26364C273.951 3.67621 291.469 2.13674 308.31 3.00874C319.748 3.60097 285.453 5.07188 273.989 5.72832C237.153 7.8377 200.302 10.0443 163.575 13.3107C161.064 13.534 123.542 16.701 123.614 18.775C123.644 19.6286 213.721 16.0467 223.761 15.6961"
								stroke={iconColor || 'red'}
								strokeLinecap="round"
							/>
						</g>
					</svg>
				)
				break
			case 'arrow6':
				svgPath = (
					<svg viewBox="0 0 337 15" fill="none" style={{ bottom: '-30%' }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2 13C68.0326 6.24788 134.995 2.72098 202.391 2.0767C247.099 1.6493 291.057 2.96859 335 7.0312"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow7':
				svgPath = (
					<svg viewBox="0 0 151 50" fill="none" style={{ bottom: '-30%' }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2 44.7795C19.6739 50.0162 35.5837 48.3989 52.8889 41.0573C66.1518 35.4306 79.2417 26.8937 88.4444 15.6684C88.7934 15.2427 98.7491 2.15797 92.5 2.00174C84.7205 1.80726 74.6736 17.9379 84.5556 21.8906C106.078 30.4995 131.532 14.2606 149 3.77952"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow8':
				svgPath = (
					<svg viewBox="0 0 108 22" fill="none" style={{ bottom: '-30%' }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2.75954 2.87085L105.971 5.6719L18.9585 10.8023C45.5939 10.2495 98.7697 11.1674 98.3904 19.2619"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow9':
				svgPath = (
					<svg
						viewBox="0 0 320 44"
						fill="none"
						style={{ bottom: '-55%', width: '100%', zIndex: 11 }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2.97745 2.66704C83.5618 2.27432 257.678 3.45172 309.469 11.3031C361.261 19.1544 148.004 17.9287 34.9023 16.3344C126.151 17.6373 308.26 24.458 306.711 41.3168"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow10':
				svgPath = (
					<svg
						viewBox="0 0 266 27"
						fill="none"
						style={{ bottom: '-35%', left: '-25%', width: '120%', zIndex: 11 }}>
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M2.29492 12.4759C53.795 6.14484 106.02 2.8379 158.584 2.2338C193.453 1.83306 240.56 1.28166 262.008 6.87931C283.456 12.477 29.5921 3.09953 36.6114 20.4458C43.6307 37.792 170.368 -1.58865 192.205 20.4458"
							stroke={iconColor || 'red'}
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			case 'arrow11':
				svgPath = (
					<svg
						style={{ bottom: '-50%', left: '-8%', width: '120%', zIndex: 11 }}
						xmlns="http://www.w3.org/2000/svg"
						width="157"
						height="35"
						viewBox="0 0 157 35"
						fill="none">
						<path
							style={{ strokeWidth: '4px' }}
							className={clsx(isInView && styles.is_inView, styles.is_Reset)}
							d="M154.941 12.3444C138.998 3.09149 123.155 0.915272 104.608 3.97349C90.3926 6.31735 75.66 11.5302 64.0718 20.2716C63.6324 20.6031 50.8744 30.9743 56.9108 32.5982C64.4256 34.62 77.9897 21.3102 69.3171 15.1407C50.4286 1.70385 21.8658 11.4894 2.41982 17.5602"
							stroke="#BFF130"
							strokeWidth="4"
							strokeLinecap="round"
						/>
					</svg>
				)
				break
			default:
				svgPath = null
				break
		}

		return svgPath
	}

	return (
		<div ref={wrapperRef} className={clsx(styles.outline_wrapper)}>
			<span style={{ color: textColor || '#000000' }}>{children}</span>
			{renderSVG(shape, iconColor as string, isInView)}
		</div>
	)
}
