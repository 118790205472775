const InstagramIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
			<path
				d="M14.744 0.466797H6.25602C2.80644 0.466797 0 3.27324 0 6.72282V15.2108C0 18.6604 2.80644 21.4668 6.25602 21.4668H14.744C18.1936 21.4668 21 18.6604 21 15.2108V6.72282C21 3.27324 18.1936 0.466797 14.744 0.466797ZM18.8874 15.2108C18.8874 17.4991 17.0323 19.3542 14.744 19.3542H6.25602C3.9677 19.3542 2.1126 17.4991 2.1126 15.2108V6.72282C2.1126 4.43445 3.9677 2.5794 6.25602 2.5794H14.744C17.0323 2.5794 18.8874 4.43445 18.8874 6.72282V15.2108Z"
				fill="white"
			/>
			<path
				d="M10.4998 5.53516C7.50498 5.53516 5.06848 7.97166 5.06848 10.9665C5.06848 13.9613 7.50498 16.3978 10.4998 16.3978C13.4947 16.3978 15.9312 13.9613 15.9312 10.9665C15.9312 7.97162 13.4947 5.53516 10.4998 5.53516ZM10.4998 14.2853C8.66693 14.2853 7.18108 12.7994 7.18108 10.9665C7.18108 9.1336 8.66696 7.64776 10.4998 7.64776C12.3327 7.64776 13.8186 9.1336 13.8186 10.9665C13.8186 12.7994 12.3327 14.2853 10.4998 14.2853Z"
				fill="white"
			/>
			<path
				d="M15.942 6.87734C16.6607 6.87734 17.2434 6.29466 17.2434 5.57588C17.2434 4.8571 16.6607 4.27441 15.942 4.27441C15.2232 4.27441 14.6405 4.8571 14.6405 5.57588C14.6405 6.29466 15.2232 6.87734 15.942 6.87734Z"
				fill="white"
			/>
		</svg>
	)
}

export default InstagramIcon
