import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import { BreakableContent } from './BreakableContent'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { useTheme } from './ThemeProvider'
import styles from './Wysiwyg.module.sass'

export type WysiwygProps = {
	source?: string
	children?: ReactNode
}

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({ source, children }) => {
	const theme = useTheme()

	return (
		<div className={clsx(styles.wrapper, styles[`is_theme_${theme.primary}`])}>
			<BreakableContent breakableFrom="768">
				{source ? <CerosRichTextRenderer source={source} /> : children}
			</BreakableContent>
		</div>
	)
}
