const twitter = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<g clipPath="url(#clip0_2250_33855)">
				<path
					d="M21.0662 4.26549C20.5996 4.47239 20.1152 4.63561 19.6182 4.75378C20.1659 4.23338 20.5776 3.58247 20.8099 2.85594C20.8762 2.64853 20.651 2.46918 20.4637 2.58023C19.7723 2.99034 19.0275 3.29801 18.25 3.49477C18.2049 3.50619 18.1584 3.51197 18.1117 3.51197C17.9701 3.51197 17.8324 3.45939 17.7241 3.36395C16.8969 2.63491 15.833 2.2334 14.7285 2.2334C14.2505 2.2334 13.768 2.30789 13.2941 2.45484C11.8261 2.91016 10.6934 4.1211 10.338 5.61512C10.2047 6.17551 10.1686 6.73641 10.2307 7.2822C10.2378 7.34492 10.2082 7.3887 10.1899 7.40923C10.1578 7.44523 10.1123 7.46586 10.065 7.46586C10.0597 7.46586 10.0543 7.4656 10.0489 7.46509C6.83549 7.16654 3.93776 5.6184 1.88949 3.1059C1.78504 2.97775 1.58475 2.99347 1.5015 3.13626C1.10039 3.82439 0.888425 4.61145 0.888425 5.41232C0.888425 6.63969 1.38153 7.79636 2.23806 8.63957C1.87787 8.55417 1.52925 8.41967 1.20541 8.2398C1.04873 8.15276 0.855964 8.26463 0.853763 8.44384C0.831337 10.2672 1.89886 11.8896 3.49805 12.6202C3.46585 12.621 3.43364 12.6213 3.40138 12.6213C3.14789 12.6213 2.89123 12.5968 2.6386 12.5486C2.46217 12.5149 2.31394 12.6822 2.36873 12.8533C2.88779 14.4738 4.2831 15.6683 5.95285 15.9396C4.56706 16.8698 2.95328 17.3605 1.27366 17.3605L0.750031 17.3602C0.588699 17.3602 0.45225 17.4654 0.411137 17.6216C0.370637 17.7755 0.445236 17.9379 0.582811 18.0179C2.47548 19.1194 4.63434 19.7015 6.82694 19.7015C8.74618 19.7015 10.5415 19.3207 12.163 18.5696C13.6495 17.881 14.9631 16.8966 16.0673 15.6439C17.0959 14.477 17.9002 13.1298 18.4579 11.64C18.9895 10.2199 19.2705 8.70455 19.2705 7.25783V7.18892C19.2704 6.95662 19.3755 6.73805 19.5588 6.58926C20.2544 6.02452 20.8601 5.35974 21.359 4.61339C21.4907 4.41632 21.283 4.16933 21.0662 4.26549Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2250_33855">
					<rect width="21" height="21" fill="white" transform="translate(0.400146 0.466797)" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default twitter
