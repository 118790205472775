import clsx from 'clsx'
import { ButtonBase, DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import {
	FunctionComponent,
	MouseEventHandler,
	PointerEventHandler,
	useCallback,
	useState,
} from 'react'
import { scrollToTargetToTopElement } from '../utilities/scrollToTargetToTopElement'
import styles from './Button.module.sass'
import { Icon, IconName } from './Icon'

export type ButtonProps = {
	icon?: IconName
	size?: 'small' | 'medium'
	fill?: 'transparent' | 'dark' | 'light' | 'blue' | 'chartreuseGreen'
	align?: 'start' | 'center'
	outline?: boolean
	bland?: boolean
	isFullWidth?: boolean
} & Omit<SharedBaseButtonProps, 'className'> &
	DistinctBaseButtonProps

export const Button: FunctionComponent<ButtonProps> = ({
	children,
	icon,
	size = 'medium',
	fill = 'transparent',
	align = 'center',
	outline = false,
	bland = false,
	isFullWidth = false,
	onClick,
	...otherProps
}) => {
	const [ripple, setRipple] = useState<null | {
		id: number
		size: number
		x: number
		y: number
	}>(null)

	const href = otherProps.type === 'link' ? otherProps.href : null

	// Ripple effect
	const onPointerDownHandler = useCallback<
		PointerEventHandler<HTMLAnchorElement | HTMLButtonElement>
	>((event) => {
		const rect = event.currentTarget.getBoundingClientRect()
		const size = 2 * Math.sqrt(Math.pow(rect.width, 2) + Math.pow(rect.height, 2))
		setRipple({
			id: event.timeStamp,
			size,
			x: event.clientX - rect.left,
			y: event.clientY - rect.top,
		})
	}, [])

	const onClickHandler = useCallback<MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>>(
		(event) => {
			if (onClick) {
				onClick(event)
			}

			const isSmoothScrollToTarget = href?.toString().startsWith('#')

			if (isSmoothScrollToTarget) {
				if (!href) {
					console.error(`Scroll to target ID is missing`)
					return
				}
				event.preventDefault()

				const url = href.toString()
				const targetId = url.substring(1)

				scrollToTargetToTopElement(targetId)
			}
		},
		[onClick, href]
	)

	return (
		<ButtonBase
			className={clsx(
				styles.wrapper,
				styles[`is_size_${size}`],
				styles[`is_fill_${fill}`],
				styles[`is_align_${align}`],
				outline && styles[`is_outline`],
				bland && styles[`is_bland`],
				isFullWidth && styles.is_fullWidth
			)}
			onPointerDown={onPointerDownHandler}
			onClick={onClickHandler}
			{...otherProps}>
			<span className={styles.content}>
				{icon && (
					<span className={styles.icon}>
						<Icon name={icon} />
					</span>
				)}
				{children}
			</span>
			{ripple && (
				<span
					key={ripple.id}
					className={styles.ripple}
					style={{
						[`--Button-ripple-size`]: `${ripple.size}px`,
						[`--Button-ripple-x`]: `${ripple.x}px`,
						[`--Button-ripple-y`]: `${ripple.y}px`,
					}}
				/>
			)}
		</ButtonBase>
	)
}
